.login-container {
  display: flex;
  min-height: 100vh;
  background: #1C1F2E;
  position: relative;
}

.login-left-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background: #1C1F2E;
  position: relative;
}

.logo-container {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  z-index: 10;
}

.source-skills-logo {
  width: 100%;
  height: auto;
  filter: brightness(0) invert(1);
}

.logo-text {
  font-size: 9px;
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: normal;
  max-width: 180px;
  line-height: 1.4;
  word-wrap: break-word;
}

.login-form {
  width: 100%;
  max-width: 360px;
  position: relative;
  z-index: 2;
  margin-top: 100px;
}

.login-form h1 {
  display: block;
  font-size: 32px;
  color: #fff;
  margin-bottom: 20px;
  font-weight: 500;
}

.login-form input {
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  font-size: 15px;
  background: rgba(255, 255, 255, 0.03);
  color: #fff;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
}

.login-form input:focus {
  background: rgba(255, 255, 255, 0.05);
  border-color: #F5D728;
  box-shadow: 0 0 0 4px rgba(245, 215, 40, 0.1);
}

.login-form input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.login-right-panel {
  flex: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  position: relative;
  background: #151724;
  overflow: hidden;
}

.login-right-panel::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-image: url('../../layouts/source_skills_results/images/brain.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
  transform: translate(-50%, -50%);
  opacity: 0.03;
  z-index: 0;
}

.welcome-text {
  text-align: center;
  position: relative;
  z-index: 1;
  max-width: 480px;
}

.welcome-text h1 {
  font-size: 42px;
  color: #F5D728;
  margin-bottom: 24px;
  font-weight: 600;
}

.welcome-text p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 18px;
  line-height: 1.6;
  margin: 0;
}

.login-form button {
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  color: #1C1F2E;
  background: linear-gradient(135deg, #F5D728 0%, #F2C94C 100%);
  box-shadow: 0 2px 4px rgba(245, 215, 40, 0.1);
  transition: all 0.2s ease;
  cursor: pointer;
}

.login-form button:hover {
  background: linear-gradient(135deg, #F2C94C 0%, #F5D728 100%);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(245, 215, 40, 0.2);
}

.google-button {
  background: rgba(255, 255, 255, 0.05) !important;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: 52px;
  color: rgba(255, 255, 255, 0.9) !important; /* Texto mais claro */
  font-weight: 400 !important; /* Peso da fonte um pouco mais leve */
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Sombra sutil no texto */
}

.google-button:hover {
  background: rgba(255, 255, 255, 0.08) !important;
  border-color: rgba(255, 255, 255, 0.2) !important;
  transform: translateY(-1px);
  color: #fff !important; /* Texto totalmente branco no hover */
}

/* Ajustar o ícone do Google para ficar um pouco menor e mais suave */
.google-button svg {
  width: 16px;
  height: 16px;
  opacity: 0.9;
}

.divider {
  margin: 24px 0;
  text-align: center;
  position: relative;
}

.divider::before,
.divider::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}

.divider::before {
  left: 0;
}

.divider::after {
  right: 0;
}

.divider span {
  background-color: #1C1F2E;
  padding: 0 12px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
}

.remember-me {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
}

.remember-me input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin: 0;
  cursor: pointer;
  accent-color: #F5D728;
}

.remember-me label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  cursor: pointer;
}

.forgot-password {
  text-align: right;
  margin: 4px 0 20px;
}

.forgot-password a {
  color: #F5D728;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.forgot-password a:hover {
  text-decoration: underline;
}

.error-message,
.success-message {
  margin-top: 16px;
  padding: 12px;
  border-radius: 12px;
  text-align: center;
  font-size: 14px;
}

.error-message {
  background-color: rgba(255, 77, 77, 0.1);
  color: #ff4d4d;
  border: 1px solid rgba(255, 77, 77, 0.2);
}

.success-message {
  background-color: rgba(46, 204, 113, 0.1);
  color: #2ecc71;
  border: 1px solid rgba(46, 204, 113, 0.2);
}

.toggle-message {
  color: #F5D728;
  cursor: pointer;
  text-align: center;
  margin-top: 24px;
  font-size: 14px;
}

.toggle-message:hover {
  text-decoration: underline;
}

/* Responsividade */
@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }

  .logo-container {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 140px;
    z-index: 10;
  }

  .login-left-panel,
  .login-right-panel {
    flex: none;
    width: 100%;
    padding: 24px;
  }

  .login-right-panel {
    min-height: 300px;
  }

  .welcome-text h1 {
    font-size: 32px;
  }

  .welcome-text p {
    font-size: 16px;
  }

  .login-form {
    margin-top: 140px; /* Aumentado para dar espaço ao logo */
    padding: 0 20px;
  }

  .login-form h1 {
    font-size: 28px;
    margin-bottom: 16px;
  }
}

/* Adicionar ao CSS existente */
.language-selector {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 10;
}

/* Ajustar responsividade */
@media (max-width: 768px) {
  .language-selector {
    top: 20px;
    right: 20px;
  }
}

/* Ajustar o container do logo e subtítulo */
.logo-container {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  z-index: 10;
}

.source-skills-logo {
  width: 100%;
  height: auto;
  filter: brightness(0) invert(1);
}

.logo-text {
  font-size: 9px;
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: normal;
  max-width: 180px;
  line-height: 1.4;
  word-wrap: break-word;
}

/* Ajustar responsividade */
@media (max-width: 768px) {
  .logo-container {
    width: 140px;
  }
  
  .logo-text {
    font-size: 8px;
    max-width: 140px;
  }
}

/* Ajuste para o logo e título no mobile */
.login-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  position: relative;
}

.login-logo {
  max-width: 200px;
  width: 100%;
  height: auto;
  margin-bottom: 0.5rem;
}

.login-title {
  font-size: 1rem;
  color: #666;
  text-align: center;
  margin-top: 0.5rem;
  width: 100%;
}

/* Media query para ajustes mobile */
@media (max-width: 768px) {
  .login-header {
    padding: 0.5rem;
  }

  .login-logo {
    max-width: 150px;
    margin-bottom: 1rem;
  }

  .login-title {
    font-size: 0.9rem;
    margin-top: 1rem;
    padding: 0 1rem;
  }
}

/* Remover estilos duplicados e ajustar o layout mobile */
.login-container {
  display: flex;
  min-height: 100vh;
  background: #1C1F2E;
  position: relative;
}

.login-left-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background: #1C1F2E;
  position: relative;
}

/* Remover logo-container duplicado */

.login-form {
  width: 100%;
  max-width: 360px;
  position: relative;
  z-index: 2;
  margin-top: 100px;
}

/* Ajustes específicos para mobile */
@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }

  .login-left-panel {
    padding: 20px;
    min-height: 100vh;
  }

  .logo-container {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 140px;
    z-index: 10;
  }

  .source-skills-logo {
    width: 100%;
    max-width: 140px;
  }

  .logo-text {
    font-size: 8px;
    max-width: 140px;
  }

  .login-form {
    margin-top: 140px; /* Aumentado para dar espaço ao logo */
    padding: 0 20px;
  }

  .login-right-panel {
    display: none; /* Ocultar o painel direito no mobile */
  }

  .language-selector {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 10;
  }
}

/* Remover estilos duplicados do login-header */

