.language-selector {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  z-index: 1001; /* Ensure flags are above other elements */
}

.flag {
  width: 35px; /* Slightly larger for better visibility */
  height: 25px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  border-radius: 4px; /* Add slight rounding to the flags */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
}

.flag:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.flag.active {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.3); /* Add glow effect to active flag */
}
