/* Reset geral */
html, body {
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: hidden !important;
  width: 100% !important;
}

/* Sobrescrevendo estilos do MainLayout */
.main-layout {
  display: flex !important;
  min-height: 100vh !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.main-content {
  flex: 1 !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  overflow-x: hidden !important;
}

.main-content.expanded {
  margin-left: 64px !important;
  width: calc(100% - 64px) !important;
  padding: 0 !important;
}

/* Container principal do relatório */
.source-skills-result-container {
  flex: 1 !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  padding-top: 60px !important; /* Adicionando espaço para o header */
}

.source-skills-result-content {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

/* Seção do hero */
.section.center.wf-section {
  width: 100% !important;
  margin: 0 !important;
  padding: 20px !important;
  background-color: #FFD700 !important;
  position: relative !important;
  z-index: 1 !important; /* Garantir que fique abaixo do header */
}

/* Ajustes do sidebar */
.sidebar ~ .main-content {
  margin-left: 280px !important;
  width: calc(100% - 280px) !important;
}

.sidebar.collapsed ~ .main-content {
  margin-left: 0 !important;
  width: 100% !important;
}

/* Containers de conteúdo */
.w-container {
  max-width: 1200px !important;
  width: 100% !important;
  margin: 0 auto !important;
  padding: 0 20px !important;
  box-sizing: border-box !important;
}

/* Grid */
.w-row {
  width: 100% !important;
  margin: 0 !important;
  display: flex !important;
  flex-wrap: wrap !important;
}

/* Mobile */
@media screen and (max-width: 768px) {
  .main-content,
  .main-content.expanded {
    margin-left: 0 !important;
    width: 100% !important;
    padding: 0 !important;
  }

  .w-container {
    padding: 0 15px !important;
  }

  .chart_23competencias {
    margin: 0 -15px; /* Remove margens laterais em mobile */
  }
  
  /* Ajusta o container para telas menores */
  .w-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* Header ajustes */
.fixed-top-bar {
  z-index: 1002 !important; /* Garantir que fique acima do conteúdo */
}

/* Chart containers */
#chart_gauge,
#chart_1,
#chart_23competencias {
  width: 100% !important;
  max-width: 800px !important;
  margin: 0 auto !important;
  padding: 20px 0 !important;
}

/* Mobile adjustments for charts */
@media screen and (max-width: 768px) {
  /* Bar chart container */
  #chart_1 {
    min-height: 300px !important;
    padding: 10px 0 !important;
  }

  /* Radar chart container */
  #chart_23competencias {
    min-height: 400px !important;
    padding: 10px 0 !important;
    margin: 20px auto !important;
  }

  /* Gauge chart container */
  #chart_gauge {
    min-height: 200px !important;
    padding: 10px 0 !important;
  }

  /* Chart titles for mobile */
  .black-text {
    font-size: 20px !important;
    margin: 15px 0 !important;
    text-align: center !important;
  }
}

/* Estilos para a seção final - versão moderna */
.custom-report-section {
  padding: 60px 20px;
  margin: 40px 0;
  text-align: center;
  background-color: #fafafa;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.custom-report-text {
  font-size: 1.2em;
  color: #333;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 35px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 0.2px;
}

.report-buttons {
  display: inline-flex;
  align-items: center;
  background-color: white;
  padding: 4px;
  border-radius: 30px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
}

.report-button {
  display: inline-flex;
  align-items: center;
  padding: 12px 32px;
  border-radius: 25px;
  font-size: 0.95em;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  text-decoration: none;
  border: none;
  letter-spacing: 0.3px;
}

.report-button.contact-us {
  background-color: #F5D728;
  color: #1a1a1a;
}

.report-button.contact-us:hover {
  background-color: #1a1a1a;
  color: #F5D728;
}

/* Ajustes para mobile */
@media screen and (max-width: 768px) {
  .custom-report-section {
    padding: 40px 15px;
    margin: 20px 0;
  }

  .custom-report-text {
    font-size: 1.1em;
    margin-bottom: 25px;
    padding: 0 10px;
  }

  .report-button {
    padding: 10px 24px;
    font-size: 0.9em;
  }
}
