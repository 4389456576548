body {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 10vh;
  line-height: 10vh;
  font-weight: 700;
}

h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #fff;
  font-size: 38px;
  line-height: 48px;
  font-weight: 700;
}

h3 {
  margin-top: 10px;
  margin-bottom: 25px;
  color: #6b6b6b;
  font-size: 24px;
  line-height: 40px;
  font-weight: 400;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

p {
  margin-bottom: 10px;
  color: #6b6b6b;
  font-size: 16px;
  line-height: 30px;
}

a {
  color: #000;
  text-decoration: underline;
}

.section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section.center {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 560px;
  padding-top: 0vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffc100;
}

.section.column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding: 15vh 10vw;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.section.column.devices {
  height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section.column.faq {
  height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
}

.section.cta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60vh;
  padding-right: 10vw;
  padding-left: 10vw;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #40089c;
}

.section.full-img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 60px;
  padding-bottom: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.section.wide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding-top: 10vh;
  padding-bottom: 10vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #1b1b1b;
}

.section.wide.img {
  padding-bottom: 0vh;
}

.section.wide.purple {
  padding-top: 15vh;
  padding-bottom: 15vh;
  background-image: url('./images/wavy-bg.svg'), linear-gradient(135deg, #fc0a7e, #602bb6);
  background-position: 50% 50%, 0px 0px;
  background-size: cover, auto;
}

.section.grid-imgs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50vh;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 50%;
  padding-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: 1px none #000;
}

.col.sideimg {
  width: 25vw;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.col.padding {
  max-width: 50%;
  padding-right: 9vw;
  padding-left: 9vw;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-style: none;
}

.col.bg {
  overflow: hidden;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: none;
}

.col.vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.col.team {
  -webkit-flex-basis: 33%;
  -ms-flex-preferred-size: 33%;
  flex-basis: 33%;
}

.col.img {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.hero {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 999999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding: 0px 10vw;
  border: 1px none #000;
  background-color: transparent;
}

.nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.nav-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80vw;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none hsla(0, 0%, 100%, 0.1);
}

.body {
  background-color: transparent;
  font-family: 'Gothic A1', sans-serif;
}

.heading {
  margin-top: 10px;
  margin-bottom: 0px;
  color: #fff;
  font-size: 10vh;
  line-height: 9vh;
  font-weight: 500;
}

.div-block-2 {
  position: relative;
  z-index: 80;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.image {
  height: 40px;
  margin-bottom: 20px;
}

.feature-content {
  width: 50vw;
  max-width: 450px;
  padding-right: 4vw;
  padding-left: 4vw;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border: 1px none #000;
}

.feature-content.mid {
  width: 40vw;
  max-width: 500px;
}

.feature-content.icons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33.33%;
  max-width: none;
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  text-align: center;
}

.feature-content.icons.numbers {
  width: 25%;
}

.heading-2 {
  margin-top: 20px;
  margin-bottom: 40px;
  color: #fff;
  font-size: 26px;
  font-weight: 400;
}

.button {
  display: inline-block;
  margin-right: 0px;
  padding: 20px 40px;
  border-radius: 3px;
  background-color: #fc0a7e;
  -webkit-transition: background-color 500ms cubic-bezier(.789, .159, .25, 1), color 200ms cubic-bezier(.789, .159, .25, 1);
  transition: background-color 500ms cubic-bezier(.789, .159, .25, 1), color 200ms cubic-bezier(.789, .159, .25, 1);
  color: #fff;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  text-align: center;
}

.button:hover {
  background-color: #fff;
  color: #6b6b6b;
}

.button.outline {
  margin-left: 10px;
  border: 1px solid #6e6e6e;
  background-color: transparent;
  color: #5a5a5a;
}

.button.contact {
  margin-left: 10px;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px hsla(0, 0%, 100%, 0.2);
}

.button.contact:hover {
  box-shadow: inset 0 0 0 2px #fff;
  color: #fff;
}

.button.top {
  margin-left: 30px;
  padding: 20px 35px;
  font-size: 16px;
}

.button.form-button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1), cubic-bezier(.215, .61, .355, 1);
  transition-timing-function: cubic-bezier(.215, .61, .355, 1), cubic-bezier(.215, .61, .355, 1);
}

.button.form-button:hover {
  background-color: #000;
  color: #fff;
}

.h2 {
  margin-bottom: 30px;
  color: #fff;
  font-size: 44px;
  line-height: 56px;
  font-weight: 300;
}

.p {
  color: #6b6b6b;
  font-size: 16px;
  line-height: 30px;
}

.p.list {
  margin-bottom: 0px;
}

.div-block-3 {
  height: 8600px;
  background-image: url('./images/richard-01.jpg');
  background-position: 50% 0px;
  background-size: 100vw;
  background-repeat: no-repeat;
}

.div-block-3._02 {
  background-image: url('./images/richard-02.jpg');
  background-size: 100vw;
}

.div-block-3._03 {
  height: 6000px;
  background-image: url('./images/richard-03.jpg');
  background-size: 100vw;
}

.div-block-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 65%;
  max-width: 440px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-4.left {
  margin-bottom: 30px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.img-device {
  height: auto;
  max-height: 450px;
}

.h3 {
  margin-bottom: 20px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.paragraph {
  margin-bottom: 20px;
  color: #6b6b6b;
  font-size: 16px;
  line-height: 28px;
}

.div-block-5 {
  padding-top: 30px;
  padding-bottom: 60px;
  text-align: center;
}

.wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1200px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.wrapper.welcome {
  position: relative;
  z-index: 90;
  width: 100vw;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.wrap {
  max-width: 700px;
  padding-top: 20px;
  padding-bottom: 40px;
  text-align: center;
}

.title {
  display: inline-block;
  padding-bottom: 5px;
  border-bottom: 1px none #2e2e2e;
  color: #a8a8a8;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.title.white {
  border-bottom-color: hsla(0, 0%, 100%, 0.4);
  color: hsla(0, 0%, 100%, 0.6);
}

.image-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-bottom: 100px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.div-block-7.team {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.div-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 150px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.button-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin-top: 5vh;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.image-3 {
  padding: 10px 30px 30px 0px;
}

.thumb-food {
  width: 100%;
  height: 300px;
  border-radius: 20px;
  background-color: #c6ced3;
}

.thumb-food._01 {
  border-radius: 12px;
  background-image: url('./images/bg-001.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.thumb-food._02 {
  background-image: url('./images/bg-001_1.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.thumb-food._03 {
  background-image: url('./images/bg-003.jpg');
  background-position: 0px 0px;
  background-size: cover;
}

.text-block {
  color: #fff;
}

.h4 {
  margin-bottom: 10px;
  color: #fff;
}

.h4.faq {
  margin-top: 20px;
  margin-bottom: 10px;
}

.nav-link {
  margin-right: 15px;
  margin-bottom: 20px;
  margin-left: 15px;
  padding-top: 40px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.nav-link:hover {
  box-shadow: inset 0 -5px 0 0 #fc0a7e;
  color: #fff;
}

.nav-menu {
  height: auto;
}

.div-block-11 {
  width: 100%;
  height: 300px;
  border-radius: 20px;
  background-color: #acb1b4;
}

.ico {
  height: 35px;
  margin-right: 20px;
}

.div-block-12 {
  margin-top: 50px;
}

._w-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 56px;
  height: 80px;
  margin-right: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.field-newsletter {
  width: 340px;
  height: 80px;
  margin-right: 20px;
  margin-bottom: 0px;
  padding-left: 20px;
  border: 1px solid #300479;
  border-radius: 10px;
  background-color: #300479;
  color: #fff;
  font-size: 16px;
}

._w-img {
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.image-4 {
  display: block;
  width: 100%;
}

.icon {
  height: 25px;
}

.nav-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.hero-image {
  position: -webkit-sticky;
  position: sticky;
  left: 30%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: block;
  width: 480px;
  height: auto;
  max-height: none;
  max-width: 100%;
  padding-top: 0px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.light-blue {
  color: #9b68f0;
}

.black-text {
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-weight: 800;
}

.hero-content {
  position: relative;
  z-index: 90;
  width: 100%;
  padding-right: 10vh;
  padding-left: 10vw;
}

.feature-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90vw;
  margin-top: 10vh;
  margin-bottom: 10vh;
  padding-right: 10vw;
  padding-left: 10vw;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
}

.feature-wrap.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.feature-wrap.logos {
  height: auto;
  padding-top: 5vh;
  padding-bottom: 5vh;
  border-style: none;
}

.feature-wrap.icons {
  padding-right: 0vw;
  padding-left: 0vw;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.feature-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50vw;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
}

.black {
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 32px;
  font-weight: 800;
}

.hero-subhead {
  max-width: 60%;
  color: #fff;
  font-size: 30px;
  line-height: 44px;
}

.bottom-image-wrap {
  position: relative;
  overflow: hidden;
  height: auto;
  max-width: 100%;
}

.black-bar {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100px;
  background-color: #2a2326;
}

.feature-icon {
  height: 35px;
  margin-bottom: 30px;
}

.purple {
  color: #b32e6e;
}

.footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.footer-link {
  margin-right: 15px;
  margin-left: 15px;
  color: #6b6b6b;
  font-size: 12px;
  text-decoration: none;
}

.footer-link:hover {
  color: #fff;
}

.footer-list {
  padding-top: 29px;
  padding-bottom: 29px;
}

.text-block-2 {
  color: hsla(0, 0%, 100%, 0.3);
  font-size: 12px;
}

.form-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
}

.input {
  height: 70px;
  margin-bottom: 0px;
  padding-right: 0px;
  padding-left: 20px;
  clear: none;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: none;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #fff;
  box-shadow: inset 0 0 0 0 #fc0a7e;
  -webkit-transition: box-shadow 300ms cubic-bezier(.215, .61, .355, 1);
  transition: box-shadow 300ms cubic-bezier(.215, .61, .355, 1);
  color: #000;
}

.input:hover {
  box-shadow: inset 0 0 0 2px #fc0a7e;
}

.input:focus {
  box-shadow: inset 0 0 0 2px #fc0a7e;
}

.input::-webkit-input-placeholder {
  color: #555;
}

.input:-ms-input-placeholder {
  color: #555;
}

.input::-ms-input-placeholder {
  color: #555;
}

.input::placeholder {
  color: #555;
}

.arrow {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 30px;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.quote-slider {
  width: 90vw;
  height: 60vh;
  margin-top: 10vh;
  background-color: transparent;
}

.slider-arrow {
  color: #d6d6d6;
}

.quote-wrap {
  display: block;
  width: 50vw;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.quote-attribution {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.quote-dash {
  width: 30px;
  height: 1px;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-left: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #d3d3d3;
}

.quote-icon {
  margin-bottom: 45px;
}

.slide-nav {
  font-size: 7px;
}

.number {
  font-size: 60px;
  line-height: 90px;
}

.float {
  position: relative;
  left: 567px;
  top: 200px;
  width: 40vw;
  height: 50vh;
  border: 4px solid #09a4ec;
}

.nav-logo-wrap {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.image-licensing {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.05);
  line-height: 24px;
}

.image-licensing-wrap {
  /* max-width: 600px; */
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.columns {
  width: 100%;
  height: 740px;
  margin-top: 20px;
  margin-bottom: 0px;
  background-color: #ffc100;
}

.image-5 {
  width: 40%;
  max-width: 60%;
  padding-top: 0px;
}

.image-6 {
  width: 40%;
  max-width: 60%;
  padding-top: 20px;
}

.paragraph-2 {
  font-family: 'Open Sans', sans-serif;
}

.section-2 {
  padding-right: 80px;
  padding-left: 80px;
}

.paragraph-3 {
  font-family: 'Open Sans', sans-serif;
}

.black-text-name {
  padding-top: 40px;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 32px;
  line-height: 40px;
  font-weight: 800;
}

.black-text-signature {
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 32px;
  font-weight: 800;
}

.image-7 {
  width: auto;
  max-height: 0%;
  max-width: 100%;
}

.column-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0px;
  padding-left: 20px;
  clear: left;
  /* -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; */
}

.image-8 {
  padding-right: 40px;
  padding-left: 60px;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.column-4 {
  position: relative;
}

@media screen and (max-width: 991px) {
  h2 {
    font-size: 34px;
    line-height: 44px;
  }

  h3 {
    font-size: 20px;
    line-height: 32px;
  }

  .section.center {
    /* height: auto; */
    padding-top: 0vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #ffc100;
    background-image: none;
    width: 100vw;
    /* height: 70vmax; */
  }

  .hero {
    padding-right: 0vw;
    padding-left: 0vw;
  }

  .nav {
    width: 100vw;
  }

  .nav-inner {
    width: 100vw;
  }

  .nav-link {
    padding-top: 20px;
  }

  .nav-link:hover {
    box-shadow: none;
  }

  .nav-menu {
    position: absolute;
  }

  .hero-image {
    position: relative;
    left: -15.2vw;
    top: auto;
    right: 0vw;
    bottom: 0px;
    display: block;
    width: 400px;
    height: auto;
    margin-right: auto;
    margin-bottom: 7vw;
    margin-left: auto;
  }

  .hero-content {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .feature-wrap {
    margin-top: 5vh;
    margin-bottom: 5vh;
    padding-right: 0vw;
    padding-left: 0vw;
  }

  .feature-wrap.logos {
    margin-top: 5vh;
    margin-bottom: 5vh;
  }

  .hero-subhead {
    font-size: 24px;
    line-height: 31px;
  }

  .bottom-image-wrap {
    height: auto;
    background-image: none;
  }

  .feature-icon {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .quote-slider {
    width: 100vw;
    height: auto;
    min-height: 500px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .quote-wrap {
    width: 70vw;
  }

  .menu-icon {
    color: #fff;
    font-size: 40px;
  }

  .menu-button.w--open {
    background-color: #000;
  }

  .nav-menu-2 {
    min-width: 240px;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: #000;
  }

  .brand {
    padding-left: 18px;
  }

  .heading-3 {
    font-size: 8vw;
  }

  .columns {
    height: 440px;
    max-height: none;
    max-width: 1440px;
  }

  .image-5 {
    width: 60%;
    max-width: 80%;
  }

  .image-6 {
    width: 60%;
    max-width: 80%;
    padding-top: 20px;
  }

  .section-2 {
    padding-right: 40px;
    padding-left: 40px;
  }

  .black-text-name {
    font-size: 24px;
    line-height: 20px;
  }

  .column-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
}
.column-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
@media screen and (max-width: 767px) {
  h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .feature-content.icons {
    width: auto;
    padding-right: 0px;
    padding-bottom: 30px;
    padding-left: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left;
  }

  .feature-content.icons.numbers {
    width: 50%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .button.form-button {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 3px;
  }

  .wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: none;
    padding-right: 15vw;
    padding-left: 15vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .hero-image {
    left: -5vw;
    width: 360px;
    margin-bottom: 10vw;
  }

  .hero-content {
    /* margin-bottom: 5vh; */
  }

  .feature-wrap {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .feature-wrap.icons {
    display: block;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .feature-wrap.icons.numbers {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .footer-link {
    display: inline-block;
    margin-bottom: 10px;
  }

  .form-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .input {
    padding-right: 22px;
    padding-left: 22px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0px;
    text-align: center;
  }

  .quote-attribution {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .menu-icon {
    font-size: 30px;
  }

  .form-block {
    width: 100%;
  }

  .image-5 {
    width: 20%;
    padding-top: 0px;
  }

  .image-6 {
    width: 20%;
    padding-top: 10px;
    padding-left: 0px;
  }

  .black-text-name {
    font-size: 20px;
    line-height: 20px;
  }

  .column-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .section{
    width: max-content;
  }
  .w-container
  {
    width:100vw;
    margin-left:1.5vw;
    margin-right:1.5vw;
  }

  .section.center
  {
    width: 103vw;
  }

}
  .chart_23competencias
  {
    /* min-height: 550px !important; */
    overflow: scroll;
  }
/* @media screen and (max-width: 479px) {
  h1 {
    font-size: 60px;
    line-height: 70px;
  }

  h3 {
    font-size: 16px;
  }

  .section.center {
    padding-bottom: 10vh;
    
  }

  .hero {
    padding-right: 0vw;
    padding-left: 0vw;
  }

  .nav-inner {
    width: 100vw;
  }

  .feature-content {
    width: auto;
  }

  .feature-content.icons.numbers {
    padding-right: 15px;
    padding-left: 15px;
    text-align: center;
  }

  .button.contact {
    width: 100%;
    margin-top: 10px;
    margin-left: 0px;
  }

  .wrap {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .image-2 {
    width: 100%;
  }

  .button-wrap {
    width: 90vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .hero-image {
    left: 0vw;
    width: 80%;
    display:none;
  }

  .feature-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .feature-wrap.reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .feature-image {
    width: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .feature-icon {
    margin-bottom: 20px;
  }

  .form-2 {
    width: 90vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .quote-slider {
    width: 100vw;
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .slider-arrow {
    font-size: 24px;
  }

  .left-arrow {
    width: 15vw;
  }

  .right-arrow {
    width: 15vw;
  }

  .number {
    font-size: 40px;
  }

  .heading-3 {
    font-size: 42px;
    line-height: 56px;
  }

  .columns {
    height: 260px;
  }

  .image-5 {
    width: 40%;
  }

  .image-6 {
    width: 40%;
    padding-left: 0px;
    float: none;
    clear: none;
  }

  .section-2 {
    padding-right: 20px;
    padding-left: 20px;
    float: none;
  }

  .black-text-name {
    padding-top: 0px;
    font-size: 16px;
  }

  .column-3 {
    padding-right: 0px;
    padding-left: 0px;
  }
} */
