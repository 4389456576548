/* Layout principal */
.survey-view-container {
  display: flex;
  min-height: 100vh;
  position: relative;
  padding-top: 60px;
}

/* Barra superior */
.fixed-top-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #1a1817;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 1002;
}

/* Sidebar */
.sidebar {
  position: fixed;
  left: 0;
  top: 60px;
  width: 280px;
  height: calc(100vh - 60px);
  background-color: #1a1817;
  color: #fff;
  transition: transform 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

/* Conteúdo principal */
.survey-content {
  margin-left: 280px;
  width: calc(100% - 280px);
  transition: margin-left 0.3s ease;
}

/* Quando o sidebar está colapsado */
.sidebar.collapsed {
  transform: translateX(-280px);
}

.sidebar.collapsed ~ .survey-content {
  margin-left: 0;
  width: 100%;
}

/* Elementos do sidebar */
.sidebar-header {
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar .logo {
  max-width: 180px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.sidebar-content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* Botão de toggle */
.toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  cursor: pointer;
  font-size: 20px;
}

/* Mensagem de boas-vindas */
.welcome-message {
  padding: 16px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  margin-bottom: 20px;
}

.welcome-message h2 {
  font-size: 20px;
  margin-bottom: 8px;
  color: #fff;
}

.user-name {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.9);
}

/* Botão de logout */
.logout-button {
  margin-top: auto;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

/* Ajustes para o formulário */
.survey-content .sv-root-modern {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 auto !important;
}

.survey-content .sv-container-modern {
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 !important;
  margin: 0 auto !important;
}

.survey-content .sv-body {
  padding: 0 !important;
  width: 100% !important;
}

/* Preservar o layout original do formulário */
.survey-content .bg-survey {
  padding-left: 6vh !important;
  padding-right: 6vh !important;
  min-height: calc(100vh - 60px) !important;
  width: 100% !important;
  max-width: 100% !important;
}

/* Ajustes para o conteúdo do formulário */
.survey-content .sv-question__content {
  max-width: 100% !important;
  width: 100% !important;
}

.survey-content .sv-question {
  max-width: 100% !important;
  width: 100% !important;
}

.survey-content .sv-row {
  max-width: 100% !important;
  width: 100% !important;
  margin: 0 !important;
}

/* Ajustes para elementos específicos */
.survey-content .bem_vindo {
  width: 100% !important;
  max-width: 100% !important;
}

.survey-content .text_1 {
  width: 100% !important;
  max-width: 100% !important;
}

/* Container de vídeo */
.survey-content .video-container {
  width: 100% !important;
  max-width: 100% !important;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .mobile-overlay.active {
    display: block;
  }

  .survey-content {
    margin-left: 0;
    width: 100%;
  }

  .sidebar {
    transform: translateX(-280px);
  }

  .sidebar.expanded {
    transform: translateX(0);
  }
}

/* Ajustes para telas grandes */
@media (min-width: 1200px) {
  .survey-content .sv-container-modern {
    max-width: 1400px !important;
    margin: 0 auto !important;
  }
}

/* Ajustes para telas muito grandes */
@media (min-width: 1600px) {
  .survey-content .sv-container-modern {
    max-width: 1800px !important;
  }
}

/* Mobile overlay */
.mobile-overlay {
  display: none;
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

@media (max-width: 768px) {
  .mobile-overlay.active {
    display: block;
  }
}
