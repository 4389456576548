.main-layout {
    display: flex;
    min-height: 100vh;
    background-color: #fcfaf3;
    width: 100%;
    position: relative;
}

.main-content {
    flex: 1;
    margin-left: 240px; /* Largura do sidebar */
    transition: margin-left 0.3s ease;
    padding: 40px; /* Aumentei o padding para dar mais espaço */
    min-height: 100vh;
    width: calc(100% - 240px);
    position: relative;
    overflow-x: hidden;
    background-color: #fcfaf3;
}

.main-content.expanded {
    margin-left: 64px; /* Largura do sidebar colapsado */
    width: calc(100% - 64px);
}

/* Garantir que o conteúdo não fique por baixo do sidebar */
.main-content > * {
    width: 100%;
    max-width: 100%;
}

/* Ajustes para responsividade */
@media (max-width: 768px) {
    .main-content {
        margin-left: 64px;
        width: calc(100% - 64px);
        padding: 20px;
    }
}
