/* Variáveis CSS */
:root {
  --picton-blue: #03a9e1ff;
  --black: #000000ff;
  --red: #b30000ff;
  --school-bus-yellow: #b38f1fff;
  --black-2: #000000ff;
}

/* Estilos Gerais */
.App {
  font-family: "Raleway rev=1";
}

/* Reset básico */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Container principal */
.survey-view-container {
  display: flex;
  min-height: 100vh;
  padding-top: 60px;
}

/* Barra superior - mais específica e forçada */
.fixed-top-bar {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  height: 60px !important;
  background-color: #1a1817 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 0 40px !important;
  z-index: 1002 !important;
  box-sizing: border-box !important;
}

/* Botão de fechar (X) */
.fixed-top-bar .close-button {
  position: absolute !important;
  right: 40px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  cursor: pointer !important;
  color: #fff !important;
  z-index: 1003 !important;
}

/* Conteúdo principal */
.survey-content {
  flex: 1;
  margin-left: 280px;
  position: relative;
  transition: margin-left 0.3s ease;
}

/* Container do iframe */
.iframe-container {
  position: fixed;
  top: 60px;
  left: 280px;
  right: 0;
  bottom: 0;
  transition: left 0.3s ease;
}

/* Loading */
.centralizar-vertical-horizontal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

/* Estrutura base do formulário */
.bg-survey {
  min-height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

/* Forçar estilos importantes */
.sv-root-modern,
.sv-container-modern,
.sv-body,
.sv-page {
  width: 100% !important;
  height: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: visible !important;
}

/* Logo */
.sv-logo {
  display: block !important;
  margin: 0 0 20px 0 !important;
  padding: 0 !important;
}

.sv-logo img {
  max-width: 400px !important;
  height: auto !important;
  display: block !important;
}

/* Quando o sidebar está colapsado */
.sidebar.collapsed ~ .survey-content {
  margin-left: 0;
}

.sidebar.collapsed ~ .survey-content .iframe-container {
  left: 0;
}

/* Ajustes responsivos */
@media screen and (max-width: 768px) {
  .survey-content {
    margin-left: 0;
  }

  .iframe-container {
    left: 0;
  }
}

/* Utilitários */
.hidden {
  display: none !important;
}

/* Ajustes para o vídeo */
.sv-question__content .video-container:not(:first-child) {
  display: none !important;
}

.video-container {
  position: relative !important;
  width: 100% !important;
  padding-bottom: 56.25% !important;
  height: 0 !important;
  overflow: hidden !important;
  margin-bottom: 20px !important;
}

.video-container iframe {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

/* Seletor de idioma - ajustado para não interferir com o X */
.fixed-top-bar .language-selector {
  margin-right: 80px !important;
  display: flex !important;
  align-items: center !important;
  gap: 12px !important;
  height: 60px !important;
  padding: 0 !important;
  position: static !important;
}

.fixed-top-bar .language-selector img {
  width: 32px !important;
  height: 24px !important;
  cursor: pointer !important;
  transition: transform 0.2s ease !important;
  object-fit: cover !important;
}

/* Responsividade */
@media (max-width: 768px) {
  .fixed-top-bar {
    padding: 0 20px !important;
  }

  .fixed-top-bar .close-button {
    right: 20px !important;
  }

  .fixed-top-bar .language-selector {
    margin-right: 60px !important;
  }

  .fixed-top-bar .language-selector img {
    width: 28px !important;
    height: 21px !important;
  }
}

/* Ajustar o logo do sidebar */
.sidebar .logo-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.sidebar .source-skills-logo {
  width: 180px;
  height: auto;
  filter: brightness(0) invert(1); /* Faz o logo ficar branco */
}

.sidebar .logo-text {
  font-size: 9px;
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: normal;
  max-width: 180px;
  line-height: 1.4;
  word-wrap: break-word;
}

/* Responsividade */
@media (max-width: 768px) {
  .sidebar .logo-container {
    padding: 16px;
  }
  
  .sidebar .source-skills-logo {
    width: 140px;
  }
  
  .sidebar .logo-text {
    font-size: 8px;
    max-width: 140px;
  }
}
