/* CSS Variables */
:root {
    --picton-blue: #03a9e1ff;
    --black: #000000ff;
    --red: #b30000ff;
    --school-bus-yellow: #b38f1fff;
    --black-2: #000000ff;
  }
  .sv-title {
    margin-top: 0px !important; /* Adjust this value to change the upper margin of the title */
  }
  .sv-container-modern{
     margin-bottom: 0px !important;
  }
  .sv-container-modern__title{
       padding-top: 1em !important;
  }
  .sv-logo {
    float: none !important;
    text-align: left;
    margin-bottom: 15px;
  }
  
  .bg-survey {
    background-color: #fcfaf3;
      padding-left: 0; 
      padding-right: 0;
  }
  
  .sv-page {
    font-family: "Raleway", sans-serif;
    color: var(--black) !important;
  }
  
  .sv-btn {
    background-color: var(--school-bus-yellow) !important;
    color: var(--black) !important;
    font-size: 18px;
    padding-right: 32px;
    padding-left: 32px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  
  .sv-progress__bar {
    background-color: #1a1817 !important;
  }
  
  .text_1 {
    max-width: 600px;
  }
  
  div[data-key="imagem_capa0"] {
    text-align: left !important;
  }
  
  div[data-key="bem_vindo-1"] {
    margin: 0;
    position: relative;
    -ms-transform: translateY(15%);
    transform: translateY(15%);
  }
  
  .sv-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  
  .video-container {
    flex: 1;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .text-container {
    flex: 1;
    padding: 10px;
    box-sizing: border-box;
  }
  
  @media (max-width: 768px) {
    .flex-col {
      flex-direction: inherit;
    }
   .sv-btn{
   font-size:14px !important;
  }
  }
  .sv-body__footer.sv-footer.sv-action-bar, .sv-body__page, .sv-body__timer
  {
  margin-top: 0px !important;
  }
  .corpo_esquerdo{
  margin-top: 2vw !important;
  }
  .lime-bg-dark{
  background-color: #1a1817 !important;
  }
  .modal-body
  {
      max-height: 70vh;
      overflow-y: auto;
      padding: 20px;
  }
  .bem_vindo{
  line-height: normal !important;
  }
  .body{
  background-color: #fcfaf3 !important;
  }
  .h4{
       color: #000 !important;
  }
  .sv-logo--right
  {
  margin-left:0 !important;
  }
  

/* Escopo todo o CSS do survey */
.source-skills-survey {
    /* Reset de estilos */
    all: initial;
    font-family: "Raleway", sans-serif;
    
    /* Variáveis CSS */
    --picton-blue: #03a9e1ff;
    --black: #000000ff;
    --red: #b30000ff;
    --school-bus-yellow: #b38f1fff;
    --black-2: #000000ff;
}

/* Aplicar estilos apenas dentro do escopo do survey */
.source-skills-survey .sv-title {
    margin-top: 0px !important;
}

.source-skills-survey .sv-container-modern {
    margin-bottom: 0px !important;
}

/* ... resto do CSS com o prefixo .source-skills-survey ... */

/* Garantir que os estilos do survey não vazem para fora */
.source-skills-survey * {
    box-sizing: border-box;
}

/* Manter o layout do survey isolado */
.source-skills-survey .sv-container-modern {
    width: 100%;
    max-width: none;
    padding: 20px;
    background-color: #fcfaf3;
}

.container.tim-container {
    padding: 0 15px;
}

/* Ajustar o container principal do survey */
.source-skills-survey {
  padding-top: 60px; /* Altura do header */
}

/* Ajustar o logo e o espaçamento superior */
.sv-logo {
  float: none !important;
  text-align: left;
  margin: 40px 0 24px !important; /* Aumentar margem superior */
  padding: 0 40px !important; /* Alinhar com o padding lateral */
}

/* Ajustar o container do survey */
.bg-survey {
  background-color: #fcfaf3;
  padding: 0 40px; /* Padding lateral consistente */
  min-height: calc(100vh - 60px); /* Altura total menos o header */
  margin-top: 60px; /* Espaço para o header fixo */
}

/* Ajustar o header fixo e seu conteúdo */
.fixed-top-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #1a1817;
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Alinhar conteúdo à direita */
  padding: 0 40px;
  z-index: 1002;
  box-sizing: border-box; /* Garantir que o padding não afete a altura */
}

/* Ajustar as bandeiras */
.language-selector {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 60px; /* Mesma altura do header */
  padding: 0; /* Remover padding extra */
}

.language-selector img {
  width: 32px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.2s ease;
  object-fit: cover;
}

.language-selector img:hover {
  transform: translateY(-1px);
}

/* Remover qualquer posicionamento absoluto ou fixo adicional */
.language-selector {
  position: static;
  top: auto;
  right: auto;
}

/* Responsividade */
@media (max-width: 768px) {
  .bg-survey {
    padding: 0 20px;
  }

  .sv-logo {
    padding: 0 20px !important;
    margin: 24px 0 16px !important;
  }

  .fixed-top-bar {
    padding: 0 20px;
  }

  .language-selector {
    gap: 8px;
  }

  .language-selector img {
    width: 28px;
    height: 21px;
  }
}
