
.grid-rows-layout {
    grid-template-rows: 1fr auto;
  }
  
  .grid {
    display: grid;
  }
  .conversationStyle  {
    position: fixed;
    left: 0px;
    top: 0px; 
    bottom: 0px; 
    height: calc(100%); 
    width: 100%;
    overflow-y: auto;
  };

  .phantomConversationStyle {
    display: block;
    height: calc(100% );
    width: 100%;
  }